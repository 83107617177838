import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import React, { useState } from 'react'

const EXAMPLE_ITEMS: { id?: number, name: string }[] = [
    { id: 1, name: 'Tom Cook' },
    { id: 2, name: 'Wade Cooper' },
    { id: 3, name: 'Tanya Fox' },
    { id: 4, name: 'Arlene Mccoy' },
    { id: 5, name: 'Devon Webb' },
]

export default function ComboBox({ items = EXAMPLE_ITEMS, selectedItem = null, onChange = (item) => { }, disabled = false }) {
    const [query, setQuery] = useState('')

    const onItemChanged = (item) => {
        onChange(item)
    }

    const filteredPeople =
        query === ''
            ? items
            : items.filter((item) => {
                return item.name.toLowerCase().includes(query.toLowerCase())
            })
    return (
        <Combobox
            value={selectedItem}
            onChange={onItemChanged}
            immediate={true}
            disabled={disabled}
        >
            <div className={clsx(
                "relative w-full",
                disabled ? "bg-gray-100 cursor-not-allowed" : "bg-white",
            )}>
                <ComboboxInput
                    className={clsx(
                        'ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500',
                        'w-full rounded-md border-none py-1.5 pr-10 pl-3 text-sm/6 text-gray-900 shadow-sm ring-1',
                        'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25',
                    )}
                    disabled={disabled}
                    displayValue={(item: any) => item?.name}
                    onChange={(event) => setQuery(event.target.value)}
                />
                <ComboboxButton className={clsx(
                    "group absolute inset-y-0 right-0 px-2.5",
                )}>
                    <ChevronDownIcon className="size-4 fill-gray-400 group-data-[hover]:fill-gray-500" />
                </ComboboxButton>
            </div>
            <Transition
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery('')}
            >
                <ComboboxOptions
                    anchor="bottom start"
                    className={clsx(
                        "absolute z-[200] mt-1 max-h-100 overflow-auto w-fit rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
                        "rounded-md",
                        "w-[var(--input-width)] border [--anchor-gap:var(--spacing-1)] empty:hidden")}
                >
                    {filteredPeople.map((item) => (
                        <ComboboxOption
                            key={item.id}
                            value={item}
                            className={clsx(
                                "relative cursor-default select-none py-2 pl-3 left-0",
                                "group flex cursor-default items-center gap-2 py-1.5 px-3 select-none data-[focus]:bg-indigo-600")}
                        >
                            <div className="text-sm/6 text-nowrap group-data-[focus]:text-white">{item.name}</div>
                            <CheckIcon className="invisible size-5 fill-indigo-600 group-data-[selected]:visible group-data-[focus]:fill-white" />
                        </ComboboxOption>
                    ))}
                </ComboboxOptions>
            </Transition>
        </Combobox>
    )
}
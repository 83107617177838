import axios from 'axios';
import Card from 'components/Card';
import Button from 'components/Form/Button';
import EmptyForm from 'components/Form/Empty';
import Input from 'components/Form/Input';
import Textarea from 'components/Form/Textarea';
import SpinnerMedium from 'components/Spinner/Medium';
import PageTitle from 'components/Title/Page';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const ArticleViewer = () => {
    const navigate = useNavigate();
    const { articleId } = useParams();
    const [article, setArticle] = useState(null);
    useEffect(() => {
        if (_.isNil(articleId)) {
            return;
        }
        fetchData();
    }, [articleId]);

    const fetchData = async () => {
        try {
            const ret = await axios.get(`https://rest.miview.shop/article/${articleId}`);
            console.log("[fetchData] ret", ret);
            setArticle(ret.data);
        } catch (e) {
            console.error("[fetchData] error", e);
            return null;
        }
    }

    if (_.isNil(article)) {
        return <SpinnerMedium />;
    }

    return (
        <Card>
            <div className="p-3">
                <PageTitle>
                    게시글 보기
                </PageTitle>
                <Input
                    upperLabel="제목"
                    value={article.title}
                    readOnly={true}
                />
                <Textarea
                    upperLabel="내용"
                    value={article.content}
                    readOnly={true}
                />
                <div className="mt-2 flex space-x-2 justify-between">
                    <div>
                        <Button
                            onButtonClicked={() => {
                                navigate(`/test/board/${article.board.id}`);
                            }}
                        >
                            뒤로가기
                        </Button>
                    </div>
                    <div>
                        <Button
                            onButtonClicked={() => {
                                navigate(`/test/article/editor/${article.id}`);
                            }}
                        >
                            수정
                        </Button>
                    </div>
                </div>
            </div>
        </Card>
    );
}

export default ArticleViewer;

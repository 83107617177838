
import React from "react";
import { Routes, Route } from "react-router-dom";

import SpecialtyPage from "pages/Category/Specialty";
import PartPage from "pages/Category/Part";
import ConcernPage from "pages/Category/Concern";
import TreatmentPage from "pages/Category/Treatment";

export default () => (
    <Routes>
        <Route path="/specialty" element={<SpecialtyPage />}></Route>
        <Route path="/part" element={<PartPage />}></Route>
        <Route path="/concern" element={<ConcernPage />}></Route>
        <Route path="/treatment" element={<TreatmentPage />}></Route>
    </Routes>
)


import { Column } from "components/Table/Simple";
import { truncate } from "components/Table/Simple/presets";

export const columns: Column[] = [
    {
        label: "ID",
        field: "id",
    },
    {
        label: "의사명",
        field: "name",
        creatable: true,
        editable: true,
    },
    {
        label: "연혁",
        field: "description",
        render: (value, field, row) => truncate(value, 30),
        creatable: true,
        editable: true,
        textarea: true,
    },
    {
        label: "의원명",
        field: "clinic",
        render: (value, field, row) => row?.clinic?.name,
    },
    {
        label: "진료과목",
        field: "specialties",
        render: (value, field, row) => {
            return row.specialties.map((specialty: any) => specialty.name).join(", ");
        },
    },
    {
        label: "프로필 이미지",
        field: "profileImage",
        visible: false,
    }
];
import React from 'react';
import logo from 'assets/logo/logo-no-background.png';
import Card from 'components/Card';
import SimpleTable from 'components/Table/Simple';

const Home = () => {
    return (
        <div className="mx-auto max-w-screen-lg">
            <img className="w-auto p-20 mt-5" src={logo} alt="Logo" style={{ margin: "0 auto" }}></img>
        </div>
    );
}

export default Home;

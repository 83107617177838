import { PhotoIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import { classNames, selectImgs } from 'components/uiUtils';
import React, { useState } from 'react';

const ImageDropper = ({ onFilesSelected = (files) => { }, isMultiple=true }) => {
    const [isDragging, setIsDragging] = useState(false);

    const handleClick = async () => {
        const files = await selectImgs(isMultiple);
        onFilesSelected(files);
    }

    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setIsDragging(false);
        const droppedFiles = Array.from(event.dataTransfer.files);
        onFilesSelected(droppedFiles);
    };

    return (
        <div className={classNames("border border-dashed border-indigo-600 rounded-md cursor-pointer hover:bg-gray-50",
            isDragging ? 'bg-gray-50' : ''
        )}
            onClick={handleClick}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            <div className="relative flex items-center justify-center size-40">
                <div className="absolute top-1 right-1">
                    <PlusCircleIcon className="size-6 text-indigo-600"></PlusCircleIcon>
                </div>
                <div>
                    <div className="relative items-center">
                        <PhotoIcon className="size-12 text-indigo-600" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImageDropper;
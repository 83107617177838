import { Column } from "components/Table/Simple";
import { truncate } from "components/Table/Simple/presets";

export const columns: Column[] = [
    {
        label: "ID",
        field: "id",
    },
    {
        label: "의원명",
        field: "clinic",
        render: (value, field, row) => row?.clinic?.name,
    },
    {
        label: "제목",
        field: "title",
        creatable: true,
    },
    {
        label: "내용",
        field: "content",
        creatable: true,
        render: (value, field, row) => truncate(value, 30),
    },
    {
        label: "시술전사진",
        field: "beforePhotos",
        visible: false,
    },
    {
        label: "시술후사진",
        field: "afterPhotos",
        visible: false,
    },
    {
        label: "이벤트",
        field: "event",
        render: (value, field, row) => row?.event?.title,
    },
    {
        label: "진료과목",
        field: "treatments",
        render: (value, field, row) => row?.treatments?.map((t) => t.name).join(", "),
    },
    {
        label: "시술의사",
        field: "doctors",
        render: (value, field, row) => row?.doctors?.map((d) => d.name).join(", "),
    }
];
import React from "react";
import Card from "components/Card";
import Button from "components/Form/Button";
import { useDaumPostcodePopup } from 'react-daum-postcode';

const DAUM_ADDRESS_CODE_SEARCH_URL = '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';

const AddressSearch = () => {
    const open = useDaumPostcodePopup(DAUM_ADDRESS_CODE_SEARCH_URL);

    const onButtonClicked = async () => {
        open({
            onComplete: (data) => {
                console.log("onComplete", data);
            },
            onSearch: (query) => {
                console.log("onSearch", query);
            },
        });
    }

    return (
        <Card>
            <div className="p-3">
                <Button onButtonClicked={onButtonClicked}>주소검색</Button>
            </div>
        </Card>
    )
}

export default AddressSearch;

import React from "react";
import ImageCard from "../ImageCard";
import ImageDropper from "../ImageDropper";
import _ from "lodash";

const SingleImageUploader = ({ imgUrl = "", onFileSelected = (file) => { }, onChange = (url) => { } }) => {
    return (
        <div className="overflow-auto">
            <div className="flex justify-center p-2 min-w-max">
                <div className="w-40 h-40">
                    {!_.isEmpty(imgUrl) ?
                        <div className="border border-dashed border-indigo-600 rounded-md cursor-pointer hover:bg-gray-50 overflow-hidden">
                            <ImageCard
                                url={imgUrl}
                                index={0}
                                onRemove={(url, index) => { onChange(null) }}
                            />
                        </div>
                        :
                        <ImageDropper
                            onFilesSelected={onFileSelected}
                            isMultiple={false}
                        />
                    }
                </div>

            </div>
        </div>
    )
}

export default SingleImageUploader;

import { Column } from "components/Table/Simple";

export const columns: Column[] = [
    {
        label: "ID",
        field: "id",
    },
    {
        label: "고민명",
        field: "name",
        creatable: true,
        editable: true,
    },
    {
        label: "시술부위",
        field: "parts",
        render: (value, field, row) => {
            return row.parts.map((part: any) => part.name).join(", ");
        }
    },
    {
        label: "시술명",
        field: "treatments",
    },
    {
        label: "설명",
        field: "description",
        creatable: true,
        editable: true,
    },
];
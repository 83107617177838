import { Column } from "components/Table/Simple";

export const columns: Column[] = [
    {
        field: "id",
        label: "ID",
    },
    {
        field: "title",
        label: "제목",
        creatable: true,
        editable: true,
    },
    {
        field: "content",
        label: "내용",
        creatable: true,
        editable: true,
    }
]
import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { DragListProps, Item } from '../types';
import { PauseIcon } from '@heroicons/react/20/solid';
import { classNames } from 'components/uiUtils';

// fake data generator
const getItems = (count: number): Item[] =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k}`,
        content: `우리아이 건강 ${k}`,
    }));

// a little function to help us with reordering the result
const reorder = (list: Item[], startIndex: number, endIndex: number): Item[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const getItemStyle = (isDragging: boolean, draggableStyle: React.CSSProperties): React.CSSProperties => ({
    userSelect: 'none',
    // padding: grid * 2,
    // margin: `0 ${grid}px 0 0`,
    background: isDragging ? 'lavenderblush' : 'white',
    ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
    background: isDraggingOver ? 'aliceblue' : 'transparent',
    display: 'flex',
    // padding: grid,
    overflow: 'auto',
});

const HorizontalDragList = ({ itemsExternal = null, setItemsExternal = (reorderedItems) => { } }) => {
    const [internalItems, setInternalItems] = useState<Item[]>(getItems(10));
    // const [currentItem, setCurrentItem] = useState(items[0]);

    const items = itemsExternal || internalItems;
    const setItems = setItemsExternal || setInternalItems;

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        const reorderedItems = reorder(
            items,
            result.source.index,
            result.destination.index
        );

        setItems(reorderedItems);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
                {(provided, snapshot) => (
                    <div
                        className="flex space-x-2"
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        {...provided.droppableProps}
                    >
                        {items.map((item, index) => (
                            <div>
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            className="flex rounded-md content-center border border-solid border-gray-300 overflow-hidden"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            // {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}

                                        >
                                            <div
                                                className="content-center bg-indigo-200"
                                                {...provided.dragHandleProps}
                                            >
                                                <PauseIcon className="h-3 w-3 mx-1" />
                                            </div>
                                            <div
                                            >
                                                <div className='text-nowrap'>
                                                    <h1 className="font-semibold">
                                                        {item.content}
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            </div>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext >
    );
};

export default HorizontalDragList;
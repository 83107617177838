import { Routes, Route, Navigate } from "react-router-dom";
import React from "react";
import MainRoute from "containers/SiteRoute/Main";
import CategoryRoute from "containers/SiteRoute/Category";
import TestRoute from "containers/SiteRoute/Test";
import Home from "pages/Home";

export default () => {
    return (
        <Routes>
            <Route path="/" element={<Navigate to={'/home/'} />}></Route>
            <Route path="/home" element={<Home />}></Route>
            <Route path="/main/*" element={<MainRoute />}></Route>
            <Route path="/category/*" element={<CategoryRoute />}></Route>
            <Route path="/test/*" element={<TestRoute />}></Route>
        </Routes>
    )
}
import { infoService } from "common/services/info";
import SpinnerMedium from "components/Spinner/Medium";
import React, { useEffect, useState } from "react";
import PublicSideNav from "containers/SideNav/public";
import PublicSiteRoute from "containers/SiteRoute/public";
import styles from './style.module.scss';

const SideNavWithContent = () => {
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        try {
            await infoService.init();
            setIsReady(true);
        } catch (e) {
            console.error("[init] error", e);
        }
    }

    if (!isReady) {
        return <SpinnerMedium></SpinnerMedium>
    }

    return (
        <div className={`${styles["main-container"]}`}>
            <PublicSideNav></PublicSideNav>
            <div className={`${styles["main-content"]}`}>
                <PublicSiteRoute></PublicSiteRoute>
            </div>
        </div>
    );
}

export default SideNavWithContent;

import React, { useState } from "react";
import Card from "components/Card";
import PagingTable from "components/Table/Paging";
import axios from "axios";
import { columns } from "./config";
import _ from "lodash";
import { Column } from "components/Table/Simple";
import { useNavigate } from "react-router-dom";
import { PencilSquareIcon } from "@heroicons/react/24/outline";

const EventPage = () => {
    const [refreshKey, setRefreshKey] = useState(_.uniqueId());
    const navigate = useNavigate();
    
    const fetchData = async (page, limit) => {
        try {
            const params = {
                offset: (page - 1) * limit,
                limit: limit
            }
            const ret = await axios.get(`https://rest.miview.shop/event`, { params });
            const { data, total } = ret.data;
            return {
                data,
                page,
                total
            }
        } catch (e) {
            console.log("[EventPage] fetchData error", e);
            return {
                data: null,
                page,
                total: 0
            }
        }
    }

    const postData = async (row) => {
        try {
            const ret = await axios.post(`https://rest.miview.shop/event`, row);
            return ret.data;
        } catch (e) {
            console.log("[EventPage] postData error", e);
            return null;
        }
    }

    const onCreate = async (row) => {
        console.log("[EventPage] onCreate", row);
        const ret = await postData(row);
        if (ret) {
            setRefreshKey(_.uniqueId());
            return true;
        }
    }

    const onDetailEditClicked = async (row)=>{
        navigate(`/main/event/editor/${row.id}`);
    }

    const columnsWithActions: Column[] = [
        {
            field: "actions",
            label: "상세수정",
            align: "center",
            render: (value, field, row) => (
                <div className="flex items-center cursor-pointer justify-center" onClick={() => { onDetailEditClicked(row) }}>
                    <PencilSquareIcon className="size-4 align-center"></PencilSquareIcon>
                </div>
            )
        },
        ...columns
    ]

    return (
        <Card>
            <div className="p-3">
                <PagingTable
                    fetchData={fetchData}
                    columns={columnsWithActions}
                    refreshKey={refreshKey}
                    windowSize={100}
                    isCrudable={true}
                    onCreate={onCreate}
                />
            </div>
        </Card>
    )
};

export default EventPage;


import { formatKoreanAddress } from "common/forms/KoreanAddressInput";
import { Column } from "components/Table/Simple";
import { truncate } from "components/Table/Simple/presets";

/*
address
specialties
bannerImage
profileImage
introductionImages
*/
export const columns: Column[] = [
    {
        label: "ID",
        field: "id",
    },
    {
        label: "의원명",
        field: "name",
        creatable: true,
        editable: true,
    },
    {
        label: "설명",
        field: "description",
        creatable: true,
        editable: true,
        textarea: true,
        render: (value, field, row) => truncate(value, 30),
    },
    {
        label: "주소",
        field: "address",
        visible: true,
        render: (value, field, row) => formatKoreanAddress(row.address),
    },
    {
        label: "전문분야",
        field: "specialties",
        render: (value, field, row) => {
            return row.specialties.map((specialty: any) => specialty.name).join(", ");
        },
        visible: false,
    },
    {
        label: "배너 이미지",
        field: "bannerImage",
        visible: false,
    },
    {
        label: "프로필 이미지",
        field: "profileImage",
        visible: false,
    },
    {
        label: "소개 이미지",
        field: "introductionImages",
        visible: false,
    },
    {
        label: "이벤트",
        field: "events",
        visible: false,
    }
];
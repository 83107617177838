import React from "react";
import { Route, Routes } from "react-router-dom";
import ClinicDetail from "common/pages/ClinicDetail";
import DoctorDetail from "common/pages/DoctorDetail";
import EventDetail from "common/pages/EventDetail";
import ReviewDetail from "common/pages/ReviewDetail";

const PreviewRoutes = () => {
    return (
        <Routes>
            <Route path="/clinic" element={<ClinicDetail />}>
                <Route path=":clinicId" element={<ClinicDetail />}></Route>
            </Route>
            <Route path="/doctor" element={<DoctorDetail />}>
                <Route path=":doctorId" element={<DoctorDetail />}></Route>
            </Route>
            <Route path="/event" element={<EventDetail />}>
                <Route path=":eventId" element={<EventDetail />}></Route>
            </Route>
            <Route path="/review" element={<ReviewDetail />}>
                <Route path=":reviewId" element={<ReviewDetail />}></Route>
            </Route>
        </Routes>
    )
}

export default PreviewRoutes;

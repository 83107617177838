import React, { useEffect, useState } from "react";
import Card from "components/Card";
import PagingTable from "components/Table/Paging";
import axios from "axios";
import { columns } from "./config";
import _ from "lodash";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { useModal } from "components/Modal/Simple";
import { infoService } from "common/services/info";
import SpinnerMedium from "components/Spinner/Medium";
import Input from "components/Form/Input";
import MultiBadgeCombo from "components/Form/MultiBadgeCombo";
import Button from "components/Form/Button";

const EditTreatmentsForm = ({ externalData = null, closeModal = () => { }, resolveRef = null }) => {
    console.log("[EditTreatmentsForm] externalData", externalData);
    const [treatmentItems, setTreatmentItems] = useState(null);
    const [selectedTreatmentItems, setSelectedTreatmentItems] = useState([]);

    useEffect(() => {
        console.log("[EditTreatmentsForm] useEffect", infoService.getTreatments());
        const treatments = infoService.getTreatments();
        setTreatmentItems(treatments);
        setSelectedTreatmentItems(externalData.treatments);
    }, [])

    const onSubmit = () => {
        console.log("[EditTreatmentsForm] onSubmit", selectedTreatmentItems);
        resolveRef.current(selectedTreatmentItems);
    }

    if (_.isNil(treatmentItems)) {
        return <SpinnerMedium />
    }

    return (
        <div>
            <div className="flex flex-col space-y-2">
                <div>
                    <div className="font-semibold">
                        진료과목명
                    </div>
                    <Input
                        disabled={true}
                        value={externalData.name}
                    />
                </div>
                <div>
                    <div className="font-semibold">
                        시술명
                    </div>
                    <MultiBadgeCombo
                        items={treatmentItems}
                        selectedItems={selectedTreatmentItems}
                        onSelectedItemsChange={setSelectedTreatmentItems}
                    />
                </div>
                <div className="border-t border-gray-300"></div>
                <div className="flex justify-end">
                    <div>
                        <Button
                            onButtonClicked={onSubmit}
                        >
                            저장
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const SpecialtyPage = () => {
    const [refreshKey, setRefreshKey] = useState(_.uniqueId());
    const { Modal: EditTreatmentsModal, openModalSync: openEditTreatmentsModal, closeModal: closeEditTreatmentsModal } = useModal();

    const fetchData = async (page, limit) => {
        try {
            const params = {
                offset: (page - 1) * limit,
                limit: limit
            }
            const ret = await axios.get(`https://rest.miview.shop/specialty`, { params });
            const { data, total } = ret.data;
            return {
                data,
                page,
                total
            }
        } catch (e) {
            console.log("[SpecialtyPage] fetchData error", e);
            return {
                data: null,
                page,
                total: 0
            }
        }
    }

    const postData = async (row) => {
        try {
            const ret = await axios.post(`https://rest.miview.shop/specialty`, row);
            return ret.data;
        } catch (e) {
            console.log("[SpecialtyPage] postData error", e);
            return null;
        }
    }

    const patchTreatmentsData = async (row, updateItems) => {
        try {
            const specialtyId = row.id;
            console.log("[patchTreatmentsData] req", specialtyId, updateItems);
            const treatmentIds = updateItems.map((item) => item.id);
            const ret = await axios.patch(`https://rest.miview.shop/specialty/${specialtyId}/treatments`, { treatmentIds });
            console.log("[patchTreatmentsData] ret", ret);
            return true;
        } catch (e) {
            console.log("[ConcernPage] patchTreatmentsData error", e);
            return null;
        }
    }

    const onCreate = async (row) => {
        console.log("[SpecialtyPage] onCreate", row);
        const ret = await postData(row);
        if (ret) {
            setRefreshKey(_.uniqueId());
            return true;
        }
    }

    const onEditTreatmentsClicked = async (row) => {
        console.log("[onEditTreatmentsClicked] row", row);
        const modalRet = await openEditTreatmentsModal(row);
        console.log("[onEditTreatmentsClicked] ret", modalRet);
        const patchRet = await patchTreatmentsData(row, modalRet);
        if (patchRet) {
            setRefreshKey(_.uniqueId());
            closeEditTreatmentsModal();
            return true;
        }
    }

    const columnsWithActions = columns.map((col) => {
        if (col.field === "treatments") {
            return {
                ...col,
                render: (value, field, row) => (
                    <div className="flex space-x-2 items-center cursor-pointer" onClick={() => { onEditTreatmentsClicked(row) }}>
                        <PencilSquareIcon className="size-4 align-center"></PencilSquareIcon>
                        <span>{row.treatments.map((item: any) => item.name).join(", ")}</span>
                    </div>
                )
            }
        }
        else {
            return col;
        }
    });

    return (
        <Card>
            <div className="p-3">
                <PagingTable
                    fetchData={fetchData}
                    columns={columnsWithActions}
                    refreshKey={refreshKey}
                    windowSize={100}
                    isCrudable={true}
                    onCreate={onCreate}
                />
            </div>
            <EditTreatmentsModal>
                <EditTreatmentsForm />
            </EditTreatmentsModal>
        </Card>
    )
};

export default SpecialtyPage;


import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import PublicSideNav from 'containers/SideNav/public';
import PublicSiteRoute from 'containers/SiteRoute/public';

import "./App.scss";
import { infoService } from 'common/services/info';
import SpinnerMedium from 'components/Spinner/Medium';
import SideNavWithContent from 'containers/Layout/SideNavWithContent';
import PreviewLayout from 'containers/Layout/Preview';

function App() {
    return (
        <Routes>
            <Route path="/preview/*" element={<PreviewLayout></PreviewLayout>}>
            </Route>
            <Route path="/*" element={
                <SideNavWithContent></SideNavWithContent>
            }>
            </Route>
        </Routes>
    );
}

export default App;
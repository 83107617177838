import React, { useState } from "react";
import Card from "components/Card";
import MultiBadgeCombo from "components/Form/MultiBadgeCombo";

const ITEMS = [
    {
        id: 1,
        name: "Apple"
    },
    {
        id: 2,
        name: "Banana"
    },
    {
        id: 3,
        name: "Cherry"
    }

]
const TestForms = () => {
    const [selectedItems, setSelectedItems] = useState([]);

    return (
        <Card>
            <div className="p-3">
                <div className="font-semibold">
                    멀티 배지 콤보
                </div>
                <div>
                    <MultiBadgeCombo 
                        items={ITEMS} 
                        selectedItems={selectedItems} 
                        onSelectedItemsChange={setSelectedItems}
                    />
                </div>
            </div>
        </Card>
    )
}

export default TestForms;

import { Column } from "components/Table/Simple";
// id, name, description
export const columns: Column[] = [
    {
        label: "ID",
        field: "id",
    },
    {
        label: "진료과목명",
        field: "name",
        creatable: true,
    },
    {
        label: "시술",
        field: "treatments",
    },
    {
        label: "설명",
        field: "description",
        creatable: true,
    },
];
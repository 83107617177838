import { Column } from "components/Table/Simple";

export const columns: Column[] = [
    {
        label: "ID",
        field: "id",
    },
    {
        label: "시술부위명",
        field: "name",
        creatable: true,
    },
    {
        label: "고민",
        field: "concerns",
    },
    {
        label: "시술명",
        field: "treatments",
    },
    {
        label: "설명",
        field: "description",
        creatable: true,
    },
];
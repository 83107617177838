
import React from "react";
import { Routes, Route } from "react-router-dom";

import ClinicPage from "pages/Clinic";
import ClinicEditPage from "common/pages/ClinicEdit";
import DoctorPage from "pages/Doctor";
import DoctorEditPage from "common/pages/DoctorEdit";
import EventPage from "pages/Event";
import EventEditPage from "common/pages/EventEdit";
import ReviewPage from "pages/Review";
import ReviewEditPage from "common/pages/ReviewEdit";
import CategoryPage from "pages/Category";

export default () => (
    <Routes>
        <Route path="/clinic" element={<ClinicPage />}></Route>
        <Route path="/clinic/editor" element={<ClinicEditPage />}>
            <Route path=":clinicId" element={<ClinicEditPage />}></Route>
        </Route>
        <Route path="/doctor" element={<DoctorPage />}></Route>
        <Route path="/doctor/editor" element={<DoctorEditPage />}>
            <Route path=":doctorId" element={<DoctorEditPage />}></Route>
        </Route>
        <Route path="/event" element={<EventPage />}></Route>
        <Route path="/event/editor" element={<EventEditPage />}>
            <Route path=":eventId" element={<EventEditPage />}></Route>
        </Route>
        <Route path="/review" element={<ReviewPage />}></Route>
        <Route path="/review/editor" element={<ReviewEditPage />}>
            <Route path=":reviewId" element={<ReviewEditPage />}></Route>
        </Route>
        <Route path="/category" element={<CategoryPage />}></Route>
    </Routes>
)


import React, { useEffect, useState } from "react";
import ComboBox from "../Combobox";
import Button from "../Button";
import Badges from "components/Badges";
import _ from "lodash";

const MultiBadgeCombo = ({ items = null, selectedItems = null, onSelectedItemsChange = (items) => { } }) => {
    console.log("[MultiBadgeCombo] items", items);
    console.log("[MultiBadgeCombo] selectedItems", selectedItems);

    const [selectedItem, setSelectedItem] = useState(null);

    const onComboboxChanged = (item) => {
        console.log("[MultiBadgeCombo] onComboboxChanged", item);
        setSelectedItem(item);
    }

    const onAddButtonClicked = () => {
        console.log("[MultiBadgeCombo] onAddButtonClicked");
        if (_.isNil(selectedItems)) {
            onSelectedItemsChange([selectedItem]);
            setSelectedItem(null);
            return;
        }
        onSelectedItemsChange([...selectedItems, selectedItem]);
        setSelectedItem(null);
    }

    const onBadgeRemoveCliced = (name) => {
        console.log("[MultiBadgeCombo] onBadgeRemoveCliced");
        onSelectedItemsChange(selectedItems?.filter((part) => part.name !== name));
    }

    useEffect(()=>{
        console.log("[MultiBadgeCombo] useEffect", items);
        setSelectedItem(null);
    },[items])

    console.log("[MultiBadgeCombo] selectedItem", selectedItem);

    return (
        <div className="flex flex-col space-y-1">
            <div>
                <div className="flex space-x-2">
                    <ComboBox
                        items={items?.filter((item) => !selectedItems?.find((part) => part.name === item.name))}
                        selectedItem={selectedItem}
                        onChange={onComboboxChanged}
                    />
                    <div>
                        <Button
                            onButtonClicked={onAddButtonClicked}
                            disabled={_.isNil(selectedItem)}
                        >
                            추가
                        </Button>
                    </div>
                </div>
            </div>
            <div className="overflow-auto max-w-fit">
                <div>
                    <Badges
                        items={selectedItems?.map((part) => part.name)}
                        onRemoveClicked={(name) => { onBadgeRemoveCliced(name) }}
                    ></Badges>
                </div>
            </div>
        </div>
    )
}

export default MultiBadgeCombo;

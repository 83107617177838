import React from "react"
import SideNav from "components/SideNav"

const menus = [
    {
        title: "메인",
        subMenus: [
            {
                title: "의원 관리",
                to: "/main/clinic",
            },
            {
                title: "의료진 관리",
                to: "/main/doctor",
            },
            {
                title: "이벤트 관리",
                to: "/main/event",
            },
            {
                title: "리뷰 관리",
                to: "/main/review",
            },
        ],
    },
    {
        title: "카테고리 관리",
        subMenus: [
            {
                title: "진료과목",
                to: "/category/specialty",
            },
            {
                title: "시술부위",
                to: "/category/part",
            },
            {
                title: "고민",
                to: "/category/concern",
            },
            {
                title: "시술명",
                to: "/category/treatment",
            }
        ],
    },
    {
        title: "테스트",
        subMenus: [
            {
                title: "이미지 업로드",
                to: "/test/img-upload",
            },
            {
                title: "주소검색",
                to: "/test/address-search",
            },
            {
                title: "폼",
                to: "/test/forms",
            },
            {
                title: "게시판",
                to: "/test/board",
            }
        ],
    }
]

export default () => {
    return (
        <SideNav menus={menus}></SideNav>
    )
}
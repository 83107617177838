import React from "react";
import ImageCard from "../ImageCard";
import ImageDropper from "../ImageDropper";
import HorizontalDragList from "components/Drag/HorizontalList";
import _ from "lodash";

const MultiImageUploader = ({ imgUrls = [], onFileSelected = (files) => { }, onChange = (imgUrls) => { } }) => {

    const onOrderChanged = (reorderedItems) => {
        console.log("onOrderChanged", reorderedItems);
        const newImgUrls = reorderedItems.map((item) => item.content.props.url);
        console.log("newImgUrls", newImgUrls);
        onChange(newImgUrls);
    }

    return (
        <div className="overflow-auto">
            <div className="flex justify-center p-2 min-w-max space-x-2">
                {/* {imgUrls.map((url, index) => (
                    <ImageCard
                        key={index}
                        url={url}
                        index={index}
                        onRemove={(url) => {
                            const newImgUrls = imgUrls.filter((imgUrl) => imgUrl !== url);
                            onChange(newImgUrls);
                        }}
                    />
                ))} */}
                <div hidden={_.isEmpty(imgUrls)}>
                    <HorizontalDragList
                        itemsExternal={imgUrls?.map((url, index) => ({
                            id: `${index}`,
                            content: (
                                <ImageCard
                                    url={url}
                                    index={index}
                                    onRemove={(url) => {
                                        const newImgUrls = imgUrls.filter((imgUrl) => imgUrl !== url);
                                        onChange(newImgUrls);
                                    }}
                                />
                            )
                        }))}
                        setItemsExternal={onOrderChanged}
                    />
                </div>

                <div className="w-40 h-40">
                    <ImageDropper onFilesSelected={onFileSelected}></ImageDropper>
                </div>
            </div>
        </div>
    )
}

export default MultiImageUploader;

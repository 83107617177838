import React from "react";
import { MinusCircleIcon } from "@heroicons/react/24/outline";

const ImageCard = ({ url, index, onRemove = (url, index) => { } }) => {
    return (
        <div className="relative overflow-hidden w-40 h-40" key={index}>
            <span className="absolute top-1 right-1">
                <MinusCircleIcon
                    className="size-6 cursor-pointer text-indigo-600"
                    onClick={() => {
                        onRemove(url, index);
                    }}
                />
            </span>
            <img
                key={index}
                src={url}
                className="w-full h-full object-cover cursor-pointer"
                onClick={() => {
                    window.open(url, 'newWindow', 'width=1280, height=720, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, directories=no, status=no');
                }}
            />
        </div>
    )
}

export default ImageCard;

import { Column } from "components/Table/Simple";

export const columns: Column[] = [
    {
        field: "id",
        label: "ID",
    },
    {
        field: "name",
        label: "이름",
        creatable: true,
        editable: true,
    },
    {
        field: "description",
        label: "설명",
        creatable: true,
        editable: true,
    }
]
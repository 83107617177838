import { Column } from "components/Table/Simple";

export const columns: Column[] = [
    {
        label: "ID",
        field: "id",
    },
    {
        label: "의원명",
        field: "clinic",
        render: (value, field, row) => row?.clinic?.name,
    },
    {
        label: "제목",
        field: "title",
        creatable: true,
    },
    {
        label: "내용",
        field: "content",
        creatable: true,
    },
    {
        label: "시술명",
        field: "treatments",
        render: (value, field, row) => row?.treatments?.map((t) => t.name).join(", "),
    },
    {
        label: "대표이미지",
        field: "mainImage",
        visible: false,
    },
    {
        label: "카드이미지",
        field: "cardImage",
        visible: false,
    },
    {
        label: "상세이미지",
        field: "detailImages",
        visible: false,
    },
    {
        label: "리뷰",
        field: "reviews",
        visible: false,
    }
];